<template>
  <section>
    <b-overlay
      :show='loading'
      opacity='0.7'
      spinner-small
      spinner-variant='primary'
      style='min-height: 200px'
      class='d-inline-block w-100'
    >
      <section class='w-100 text-center mt-3' v-if='user && user.id'>
        <h6>Usuário: <strong>{{ user.username }}</strong></h6>
        <h6>Data: <strong>{{ formatDate(user.currentDate) }}</strong></h6>
      </section>
      <section v-if='completeHierarchy.length > 0' class='mb-3'>
        <ul class='tree mt-5' v-for='(company, i) in completeHierarchy' :key='`${company._id}-${i}`'>
          <li>
            <section class='item'>
            <span>
              Empresa:&nbsp; {{ company.name }}
            </span>
            </section>
          </li>

          <ul v-for='(unit, y) in company.units' :key='`${unit._id}-${y}`'>
            <li>
              <section class='item'>
              <span>
                Unidade :&nbsp; {{ unit.name }}
              </span>
              </section>
            </li>

            <ul v-for='(sector, z) in unit.sectors' :key='`${sector._id}-${z}`'>
              <li>
                <section class='item'>
                <span>
                  Setor:&nbsp; {{ sector.name }}
                </span>
                </section>
              </li>

              <ul v-for='(role, b) in sector.roles' :key='`${role._id}-${b}`'>
                <li>
                  <section class='item'>
                  <span>
                    Cargo :&nbsp; {{ role.name }}
                  </span>
                    <span class='word-break' style='max-width: 1300px' v-if='role && role.activitiesDeveloped'>
                    Atividades desenvolvidas:&nbsp; {{ role.activitiesDeveloped }}
                  </span>
                  </section>
                </li>

                <ul v-for='(product, a) in role.products' :key='`${product._id}-${a}`'>
                  <li>
                    <section class='item'>
                      <span>
                        Produto:&nbsp; {{ product.name }}
                      </span>
                      <span v-if='product && product.physicalState'>
                        Estado físico do produto:&nbsp; {{ physicalProductStates.find((x) => x.key == product.physicalState).value
                        }}
                      </span>
                      <span v-else>
                        Estado físico do produto:&nbsp; -
                      </span>
                      <span class='word-break' style='max-width: 1300px' v-if='product && product.description'>
                        Descrição:&nbsp; {{ product.description }}
                      </span>
                      <span v-if='product && product.urlFISPQ'>
                        FDS ou FISPQ:&nbsp; <a
                        class='file-uploaded mt-3'
                        v-if='product.urlFISPQ'
                        :href='product.urlFISPQ'
                        target='_blank'
                      >
                        Acesse o sistema para ver o arquivo anexado
                         </a>
                      </span>
                    </section>
                  </li>

                  <ul>
                    <li v-if='product.termsofuses && product.termsofuses._id'>
                      <section class='item'>
                      <span>
                        Condiçoes de uso: <br />
                      </span>
                        <section>
                          <span v-if='product.termsofuses.temperature'>
                            Temperatura °C:&nbsp; {{ product.termsofuses.temperature }}
                          </span>

                          <span v-if='product.termsofuses.ventilationWorkEnvironmentGeneral'>
                          Nível de ventilação:&nbsp;
                          {{ ventilationLevels.find((x) => x.key == product.termsofuses.ventilationWorkEnvironmentGeneral).value
                            }}
                        </span>

                          <span v-if='product.termsofuses.ventilationInstalledSpecificallyForTheProductionProcess'>
                          Nível de controle da ventilação:&nbsp;
                          {{ optionsVentilationIdentified.find((x) => x.key == product.termsofuses.ventilationInstalledSpecificallyForTheProductionProcess).value
                            }}
                        </span>

                          <span v-if='product.termsofuses.quantityUsed'>
                          Quantidade diária utilizada deste produto (em kg ou L):&nbsp;
                          {{ optionsQuantityUsed.find((x) => x.key == product.termsofuses.quantityUsed).value }}
                        </span>

                          <span v-if='product.termsofuses.frequencyUse'>
                          Frequência de utilização deste produto:&nbsp;
                          {{ optionsFrequencyUse.find((x) => x.key == product.termsofuses.frequencyUse).value }}
                        </span>

                          <span v-if='product.termsofuses.exposureTime'>
                          Tempo de exposição do trabalhador com o uso do produto (por dia):&nbsp;
                          {{ optionsExposureTime.find((x) => x.key == product.termsofuses.exposureTime).value }}
                        </span>
                          <span v-if='product.termsofuses.exposedWorkers'>
                          Quantidade de trabalhadores expostos:&nbsp;
                          {{ product.termsofuses.exposedWorkers }}
                        </span>
                          <span v-if='product.termsofuses.physicalStateProduct'>
                          Estado Físico do Produto:&nbsp;
                          {{ physicalProductStates.find((x) => x.key == product.termsofuses.physicalStateProduct).value
                            }}
                        </span>
                          <span v-if='product.termsofuses.description'>
                          Descrição:&nbsp;
                          {{  product.termsofuses.description }}
                        </span>
                        </section>
                      </section>
                    </li>

                    <ul>
                      <li>
                        <section class='item'>
                          <span>
                            Agentes <br />
                          </span>

                          <span class='mb-2' v-for='(agent, a) in product.legalSearches' :key='`${agent._id}-${a}`'>
                            CAS: Agente: {{ agent.name }} <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;% do agente no produto: {{ agent.percentage }}
                          </span>
                        </section>
                      </li>
                    </ul>
                  </ul>
                </ul>
              </ul>
            </ul>
          </ul>
        </ul>
      </section>
    </b-overlay>
  </section>
</template>

<script>
import format from 'date-fns/esm/format/index'
import api from '../utils/api'
import { mapActions } from 'vuex'
import moment from 'moment/moment'
import { ventilationLevels } from '@/mocks/ventilationLevels'
import { optionsVentilationIdentified } from '@/mocks/optionsVentilationIdentified'
import { optionsExposureTime } from '@/mocks/optionsExposureTime'
import { optionsFrequencyUse } from '@/mocks/optionsFrequencyUse'
import { optionsQuantityUsed } from '@/mocks/optionsQuantityUsed'
import { physicalProductStates } from '@/mocks/physicalProductStates'

export default {
  name: 'Hierarchy',
  data() {
    return {
      completeHierarchy: [],
      simplifiedHierarchy: [],
      ventilationLevels: [],
      optionsVentilationIdentified: [],
      optionsExposureTime: [],
      optionsFrequencyUse: [],
      optionsQuantityUsed: [],
      physicalProductStates: [],
      /*user: {},*/
      companyId: null,
      loading: false
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    }
  },
  methods: {
    ...mapActions('hierarchy', {
      handleGetHierarchy: 'handleGetHierarchy'
    }),
    ...mapActions('companyLegalConsultation', {
      handleGetCompleteHierarchyCompanyLegalConsultation: 'handleGetCompleteHierarchyCompanyLegalConsultation'
    }),
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
    async getCompany() {
      try {
        const { data, status } = await this.handleGetCompleteHierarchyCompanyLegalConsultation(this.companyId)
        console.log(data)
        const { company } = data
        this.completeHierarchy = [company]
      } catch (e) {
      }
    }
  },
  async mounted() {
    try {
      Object.assign(this.ventilationLevels, ventilationLevels)
      this.ventilationLevels = ventilationLevels
      Object.assign(this.optionsVentilationIdentified, optionsVentilationIdentified)
      this.optionsVentilationIdentified = optionsVentilationIdentified
      Object.assign(this.optionsExposureTime, optionsExposureTime)
      this.optionsExposureTime = optionsExposureTime
      Object.assign(this.optionsFrequencyUse, optionsFrequencyUse)
      this.optionsFrequencyUse = optionsFrequencyUse
      Object.assign(this.optionsQuantityUsed, optionsQuantityUsed)
      this.optionsQuantityUsed = optionsQuantityUsed
      Object.assign(this.physicalProductStates, physicalProductStates)
      this.physicalProductStates = physicalProductStates
      this.loading = true
      const { companyId } = this.$route.params
      this.companyId = companyId
      await this.getCompany()
      setTimeout(() => {
        window.print()
      }, 1000)
      this.loading = false
    } catch (e) {

    }

  }
}
</script>

<style lang='scss' scoped>
.tree,
.tree ul {
  margin: 20px 0;
  padding: 0;
  list-style: none;
  color: var(--navy-blue);
  position: relative;

}

.tree ul {
  margin-left: 2.5em
}

.tree:before,
.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: -20px;
  bottom: 0;
  left: 4px;
  border-left: 1px solid #B6B6B6;
}

ul.tree:before {
  border-left: none
}

.tree li {
  margin: 0;
  padding: 0 2em;
  line-height: 2em;
  font-weight: bold;
  position: relative;
}

.tree span,
.tree folder {
  display: block;
}

.tree li:before {
  content: "";
  display: block;
  width: 30px;
  height: 0;
  border-top: 1px solid #B6B6B6;
  margin-top: 1px;
  position: absolute;
  top: 1em;
  left: 4px;
}

ul.tree > li:before {
  border-top: none;
}

.item {
  width: max-content;
  margin-left: 10px;
  //padding-left: 3px;
}

.type-hierarchy {
  margin: 0 35px -30px 35px;
  border-bottom: 1px solid var(--navy-blue);

  .text-type-hierarchy {

  }
}
</style>
